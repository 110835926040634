<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
	  <div class="home-retail">
      <div class="overlay">
        <v-container>
          <div class="display-3">
            <span class="thin">Retail</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Solutions</span>
          </div>
        </v-container>
      </div>
    </div>
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Interactive Displays and Packaging</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Bluetooth capable messaging</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Interactive customer experiences</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Real-time marketing opportunities</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Sales-generating technology</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Industries',
          disabled: false,
          exact: true,
          to: '/industries'
        },
        {
          text: 'Retail',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>